import React, { memo } from 'react';

import CustomIcon from '@/src/shared/components/Icon';

import { MenuItem, NavItem, TopMenuItem } from './components';

const NavEvents = memo(props => {
  return (
    <TopMenuItem {...props} title={<span>Events</span>} className="hideIfClosed">
      <NavItem type="default" path="/events/meetings" />
      {/* <MenuItem>
        <a href="https://apciaannualmeeting.pathable.co/" target="_blank" rel="noopener noreferrer">
          Annual Meeting
          <CustomIcon name="External" />
        </a>
      </MenuItem> */}
      <NavItem type="default" path="/events/conferences" locked={false} />
    </TopMenuItem>
  );
});

export default NavEvents;
